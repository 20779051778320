import React from "react";
import "./footer.css";
import RJ from "../../assets/rjLogo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="col-lg-12 contact">
          <div className="row row-footer">
            <div className="col-xs-1 col-lg-3" id="footerIcon">
              <i className="fa fa-globe" id="footerIcons"></i>
              <span className="footer-p">
                <b>Head Office: </b>
                Riyadh, Saudi Arabia
              </span>
            </div>

            <div className="col-xs-1 col-lg-3">
              <i className="fa fa-phone"></i>
              <span className="footer-p">
                <b>Phone : </b>
                +966-11-45-1078
              </span>
            </div>

            <div className="col-xs-1 col-lg-3">
              <i className="fa-solid fa-pager"></i>
              <span className="footer-p">
                <b>Fax : </b>
                +966-11-456-4618
              </span>
            </div>

            <div className="col-xs-1 col-lg-3">
              <i className="fa-solid fa-envelope"></i>
              <a className="aTag" href="mailto:info@misyan.org">
                <span className="footer-p">
                  <b>Email : </b>
                  info@misyan.org
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyRight">
        <small>
          &copy; Copyright Misyan Plastic Factory All Rights Reserved | Powered
          by:
          <a className="raadWeb" href="https://raadportfolio.netlify.app">
            Raad Jarrar
            <img className="raadLogo" src={RJ} />
          </a>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
