import React from "react";
import Navbar from "../../components/navbar/Navbar"
import Footer from "../../components/footer/Footer";
import MACHINE from "../../assets/DK.png";
import RECYCLING from "../../assets/rm.png";
import GH from "../../assets/gh.jpg";
import PR from "../../assets/pr.jpg";
import CR from "../../assets/cr.jpg";
import "./welcomepage.css";

const WelcomePage = () => {
  return (
    <div className="welcome-page">
      <Navbar />
      <div
        className="container machine-container three animate fadeRight"
        id="bigMachine"
      >
        <div className="row blowinFilm">
          <div className="col-lg-5 machineImage">
            <img className="machineImage" src={MACHINE} alt="machineImage" />
          </div>
          <div className="col-lg-5" id="intro">
            <h2 className='h2Head'>
              <span className="introHead">Misyan Plastic Factory</span>
            </h2>
            <p className="pIntro">
              One of the Saudi factories specialized in plastic products. We are
              located in Al-Kharj industrial city. The factory was built on an
              area of 5000 square meters. Misyan Plastic Factory relies on the
              latest machines in the field of plastic industries. The production
              capacity is 14 tons per day. We use 3 layers technology in all our
              products.
            </p>
          </div>
        </div>
      </div>

      <hr className="devider"></hr>

      <div className="container recycling-container tow animate fadeLeft">
        <div className="row recycle">
          <div className="col-lg-5" id="introR">
            <h2 className='h2Head'>
              <span className="rHead">Recycling</span>
            </h2>
            <p className="rIntro">
              The recycling stage is one of the most important stages in plastic
              industry. In addition, The benefite from this stage is preserve
              the environment through Recycling and using these materials in the
              manufacture of other materials or products, Our production
              capacity is 4 tons per day.
            </p>
          </div>
          <div className="col-lg-6 recycleImage">
            <img className="machineImg" src={RECYCLING} alt="machineImg" />
          </div>
        </div>
      </div>

      <hr className="devider"></hr>

      <div className="card-group three animate fadeRight">
        <div className="card" id="productsCard">
          <img src={PR} className="card-img-top" alt="packingRpllImg" />
          <div className="card-body">
            <h5>
              <span className="card-title">Packing roll</span>
            </h5>
            <p className="card-text" id="textPr">
              This Rolls are used for packing products of all kinds and sizes
              such as: Carpets and House Furniture.
            </p>
            <a href="/Products" className="btn" id="btnPR" target="#pRoll">
              Go to Products
            </a>
          </div>
        </div>

        <div className="card" id="productsCard">
          <img src={GH} className="card-img-top" alt="greenHouseImg" />
          <div className="card-body">
            <h5>
              <span className="card-title">Green House</span>
            </h5>
            <p className="card-text" id="textGh">
              Green houses farming one of the modern agricultural techniques to
              increase productivity.
            </p>
            <a href="/Products" className="btn" target="#ghRoll" id="btnGH">
              Go to Products
            </a>
          </div>
        </div>
        <div className="card" id="productsCard">
          <img src={CR} className="card-img-top" alt="concreteRollImg" />
          <div className="card-body">
            <h5>
              <span className="card-title">Concrete Roll</span>
            </h5>
            <p className="card-text" id="textCr">
              This type of plastic, is used between concrete and soil to
              maintain the hardness of the concrete.
            </p>
            <a href="/Products/#crRoll" className="btn" id="btnCrr">
              Go to Products
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WelcomePage;
