import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./contact.css";
import { useRef } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2a0ai1u",
        "template_kux2duf",
        form.current,
        "uAPuPcsfiatuKusbu"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="contact">
      <Navbar />
      <form className="email-form" id='cont-form'ref={form} onSubmit={sendEmail}>
        <div className="row contact-row">
          <div className="col-xs-12 col-lg-6 get">
            <h2 className="getInTouch">Get In Touch:</h2>
            <div className="row">
              <div className="col-xs-12 col-lg-12">
                <i className="fa fa-globe"></i>
                <b className="gIcons">
                  <b className="bContact"> Head Office : </b>
                  Riyadh 11682, P.O. Box 89382, K.S.A
                </b>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-lg-12">
                <i className="fa-solid fa-pager"></i>
                <b className="gIcons">
                  <b className="bContact"> Fax : </b>
                  +966-11-456-4618
                </b>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-lg-12">
                <i className="fa-solid fa-pager"></i>
                <b className="gIcons">
                  <b className="bContact"> Phone : </b>
                  +966-11-45-1078
                </b>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-lg-12">
                <a className="aTag" href="mailto:info@misyan.org">
                  <i className="fa-solid fa-envelope"></i>
                  <b className="gIcons">
                    <b className="bContact"> Email : </b>
                    info@misyan.org
                  </b>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6" id="contForm">
            <div className="container col-lg-12" id="conCol">
              <label for="exampleFormControlInput1" className="form-label" />
              <input
                type="name"
                name="name"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter Your Full Name (required)*"
                aria-required="true"
                aria-invalid="false"
              />
            </div>
            <div className="container col-lg-12" id="conCol">
              <label for="exampleFormControlInput1" className="form-label" />
              <input
                type="email"
                name="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="name@example.com (required)*"
                aria-required="true"
                aria-invalid="false"
              />
            </div>
            <div className="container col-lg-12" id="conCol">
              <label for="exampleFormControlTextarea1" className="form-label" />
              <textarea
                name="message"
                className="form-control"
                id="exampleFormControlTextarea1"
                placeholder="Write your message"
                rows="7"
                aria-required="true"
                aria-invalid="false"
              ></textarea>
              <button className="btn" type="submit" id="submitBtn">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default Contact;
