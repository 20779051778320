import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./about.css";

const About = () => {
  return (
    <div className="about">
      <Navbar />
      <div className="col one animate fadeDown" id="colId1">
        <div className="row row-xs-12 row-sm-12 row-md-12 row-lg-12" id="rowId">
          <i className="fa-solid fa-bullseye fa-2xl" />
        </div>
        <div className="row row-xs-12 row-sm-12 row-md-12 row-lg-12" id="rowId">
          <h4 className="rowHead">
            <span>Goals</span>
          </h4>
          <ul id="goals-ul">
            <li className="aboutLi">
              Covering the needs of the Saudi market for plastic products.
            </li>
            <li className="aboutLi">
              Maintaining quality and diversity in products.
            </li>
            <li className="aboutLi">Customer satisfaction.</li>
          </ul>
        </div>
      </div>

      <div className="col tow animate fadeLeft" id="colId">
        <div className="row row-xs-12 row-sm-12 row-md-12 row-lg-12" id="rowId">
          <i className="fa-solid fa-rocket fa-2xl" />
        </div>
        <div className="row row-xs-12 row-sm-12 row-md-12 row-lg-12" id="rowId">
          <h4 className="rowHead">
            <span>Mission</span>
          </h4>
          <ul id="about-ul">
            <li className="aboutLi">
              Following the latest technology to ensure continuous improvement
              of products and Understanding requirement of customer and suggest
              them optimum solution.
            </li>
            <li className="aboutLi">
              Empowering our customers by providing technologically advanced
              products at highly competitive rates to meet their needs, standard
              and requirements.{" "}
            </li>
            <li className="aboutLi">
              Encouraging development of our staff by integrating their safety,
              health, skills, values in our system to achieve better services to
              our customers.
            </li>
            <li className="aboutLi">
              Implement worldwide standards on safety, health and environmental
              conditions to ensure the achieving of industry standards.
            </li>
            <li className="aboutLi">
              Deal with qualified raw material suppliers and meet their quality
              standards.
            </li>
          </ul>
        </div>
      </div>

      <div className="col three animate fadeRight" id="colId">
        <div className="row row-xs-12 row-sm-12 row-md-12 row-lg-12" id="rowId">
          <i className="fa-solid fa-eye fa-2xl" />
        </div>
        <div className="row row-xs-12 row-sm-12 row-md-12 row-lg-12" id="rowId">
          <h4 className="rowHead">
            <span>Vision</span>
          </h4>
        </div>
        <p className="aboutP">
          In line with the Kingdom’s vision 2030, we aspire and strive to become
          the first name in the plastic industries in Saudi Arabia and Gulf
          markets.
        </p>
      </div>

      <div className="col four animate fadeUp" id="colId">
        <div className="row row-xs-12 row-sm-12 row-md-12 row-lg-12" id="rowId">
          <i className="fa-solid fa-hand-holding-heart fa-2xl" />
        </div>
        <div className="row row-xs-12 row-sm-12 row-md-12 row-lg-12" id="rowId">
          <h4 className="rowHead">
            <span>Value</span>
          </h4>
        </div>
        <p className="aboutP">
          We closely blend the ethical standards of working with integrity,
          openness, honesty, and a total commitment towards our work as our core
          values. We ensure a respectful treatment with total responsiveness to
          all our business associates. We accomplish easy accessibility to all
          levels of management for our customers, suppliers and staff by
          adopting an open work environment with effective two way
          communication.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default About;
