import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./products.css";
import PR from "../../assets/pr.jpg";
import GR from "../../assets/gh.jpg";
import CR from "../../assets/cr.jpg";
import GRR from "../../assets/gh1.jpg";
import CRR from "../../assets/cr1.jpg";
import PRR from "../../assets/pr1.jpg";


const Products = () => {
  return (
    <div className="Products">
      <Navbar />
      <div className="card one animate fadeDown" id="Rolls">
        <div className="row mainRow">
          <div className="col" id="pCol">
            <div className="row pRow">
              <img
                src={PR}
                class="img-fluid rounded-start"
                alt="prImg"
                id="RollImg"
              />
            </div>
            <div className="row pRow">
              <img
                src={PRR}
                class="img-fluid rounded-start"
                alt="prImg"
                id="RollImg"
              />
            </div>
          </div>
          <div className="col bodyCol">
            <div className="card-body">
              <h5 className="card-title">
                <span>Packing roll</span>
              </h5>

              <p className="card-text">
                <div className="uses">
                  <h6 className="h6">Uses:</h6>
                  <ul className="ulProducts">
                    <li className="liProducts">Carpets.</li>
                    <li className="liProducts">Home Furniture.</li>
                    <li className="liProducts">Office Furniture.</li>
                    <li className="liProducts">
                      All other products does not need heat in packing.
                    </li>
                  </ul>
                </div>
                <div className="productsSize">
                  <h6 className="h6">Size:</h6>
                  <ul className="ulSize">
                    <li className="liSize">Width: 70cm - 5.5m</li>
                    <li className="liSize">Length: 20m - 100m</li>
                    <li className="liSize">Thikness: 70 micron - 250 micron</li>
                  </ul>
                </div>
                <div className="productsColor">
                  <h6 className="h6">Color:</h6>
                  <ul className="ulColor">
                    <li className="liColor">
                      All colors are available upon request.
                    </li>
                  </ul>
                </div>
                <div className="productsTrans">
                  <h6 className="h6">Transparency:</h6>
                  <ul className="ulTrans">
                    <li className="liTrans">
                      The percentage of transparency can be cotrolled as per the
                      customer`s` request.
                    </li>
                  </ul>
                </div>
              </p>
              <a href="/Contact" className="btn" id="btnRoll">
                {" "}
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card tow animate fadeLeft" id="Rolls">
        <div className="row mainRow">
          <div className="col" id="pCol">
            <div className="row pRow">
              <img
                src={GR}
                className="img-fluid rounded-start"
                alt="grImg"
                id="RollImg"
              />
            </div>
            <div className="row pRow">
              <img
                src={GRR}
                className="img-fluid rounded-start"
                alt="grImg"
                id="RollImg"
              />
            </div>
          </div>
          <div className="col bodyCol">
            <div className="card-body">
              <h5 className="card-title">
                <span>Green House Roll</span>
              </h5>
              <p className="card-text">
                <div className="uses">
                  <h6 className="h6">Goals:</h6>
                  <ul className="ulProducts">
                    <li className="liProducts">Increase of productivity.</li>
                    <li className="liProducts">
                      Saving irrigation water by up to 60%.
                    </li>
                    <li className="liProducts">
                      Control of pests, diseases and insects.
                    </li>
                    <li className="liProducts">
                      Production of agricultural products outside its seasons.
                    </li>
                    <li className="liProducts">
                      Protect agricultural products from external weather
                      conditions.
                    </li>
                  </ul>
                </div>
                <div className="productsSize">
                  <h6 className="h6">Size:</h6>
                  <ul className="ulSize">
                    <li className="liSize">Width: 5.5 meters</li>
                    <li className="liSize">
                      Length: 70 meters or as costumer`s request.
                    </li>
                    <li className="liSize">
                      Thikness: 150 micron - 250 micron or as costumer`s
                      request.
                    </li>
                  </ul>
                </div>

                <div className="productsTrans">
                  <h6 className="h6">Transparency:</h6>
                  <ul className="ulTrans">
                    <li className="liTrans">
                      The percentage of transparency can be cotrolled as per the
                      customer`s` request.
                    </li>
                  </ul>
                </div>
              </p>
              <a href="/Contact" className="btn" id="btnRoll">
                {" "}
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
      
      <div className="card four animate fadeUp" id="Rolls">
        <div className="row mainRow">
          <div className="col" id="pCol">
            <div className="row pRow">
              <img
                src={CR}
                className="img-fluid rounded-start"
                alt="grImg"
                id="RollImg"
              />
            </div>
            <div className="row pRow">
              <img
                src={CRR}
                className="img-fluid rounded-start"
                alt="grImg"
                id="RollImg"
              />
            </div>
          </div>
          <div className="col bodyCol">
            <div className="card-body">
              <h5 className="card-title">
                <span>Concrete Roll</span>
              </h5>

              <p className="card-text">
                <div className="uses">
                  <h6 className="h6">Uses:</h6>
                  <ul className="ulProducts">
                    <li className="liProducts">
                      Used between the concrete and soil.
                    </li>
                    <li className="liProducts">
                      Used to maintain the hardness of the concrete.
                    </li>
                    <li className="liProducts">
                      Used to not allow the water leak.
                    </li>
                  </ul>
                </div>
                <div className="productsSize">
                  <h6 className="h6">Size:</h6>
                  <ul className="ulSize">
                    <li className="liSize">Width: 2.5 meters - 5.5 meters.</li>
                    <li className="liSize">
                      Length: 20 meters 100 meters or as costumer`srequest.
                    </li>
                    <li className="liSize">
                      Thikness: 150 micron - 250 micron or as costumer`s
                      request.
                    </li>
                  </ul>
                </div>
                <div className="productsColor">
                  <h6 className="h6">Color:</h6>
                  <ul className="ulColor">
                    <li className="liColor">
                      All colors are available upon request.
                    </li>
                  </ul>
                </div>
                <div className="productsTrans">
                  <h6 className="h6">Transparency:</h6>
                  <ul className="ulTrans">
                    <li className="liTrans">
                      The percentage of transparency can be cotrolled as per the
                      customer`s` request.
                    </li>
                  </ul>
                </div>
              </p>
              <a href="/Contact" className="btn" id="btnRoll">
                {" "}
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Products;
